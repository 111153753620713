import {
  Avatar,
  getInitials,
  SelectItem,
  Table,
  TableComponent,
  useToast,
} from "@themis/ui";
import {
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { CustomCellEditorProps, CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import { TableProps } from "packages/ui/src/components/Table/types";
import React, { useMemo } from "react";
import { generatePath } from "react-router-dom";

import { Task, ThemisRecord, TaskStatus as TTaskStatus } from "@/api";
import { useTasks, useUpdateTask } from "@/api/queries/tasks";
import { useCompanyUsers } from "@/api/queries/users";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import { TaskStatus } from "../../config/status";
import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import AssociatedRecordCell from "../old-table/AssociatedRecordCell";
import { TasksEmptyState } from "../TasksEmptyState";

type Column<TEntity extends ThemisRecord> = Omit<
  TableProps["columns"][number],
  "field"
> & {
  field?: keyof TEntity;
};

type Columns = Array<Column<Task>>;

function TasksTable() {
  const { context, taskDetail } = useMainStore();
  const toast = useToast();
  const { listRequestQueryParams, sorting, setSorting } =
    useMyTasksFilterSort();
  const {
    data: users,
    isPending: isUsersPending,
    isError: isUsersError,
  } = useCompanyUsers(Number(context.companyID));
  const {
    data: tasksData,
    isPending: isTasksPending,
    isError: isTasksError,
  } = useTasks(Number(context.companyID), listRequestQueryParams);
  const isPending = isUsersPending || isTasksPending;
  const isError = isUsersError || isTasksError;
  const { mutateAsync: updateTask } = useUpdateTask({
    companyId: Number(context.companyID),
  });

  async function handleCheckboxCellChanged(
    params: NewValueParams<Task, boolean>,
  ) {
    try {
      await updateTask({
        id: params.data.id,
        task: {
          status: params.newValue
            ? TaskStatus.Done.value
            : TaskStatus["In Progress"].value,
        },
      });
      toast({
        content: `Task marked as "${
          params.newValue
            ? TaskStatus.Done.label
            : TaskStatus["In Progress"].label
        }" successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            status: params.oldValue
              ? TaskStatus.Done.value
              : TaskStatus["In Progress"].value,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task status",
        variant: "error",
      });
    }
  }

  async function handleNameCellChanged(params: NewValueParams<Task, string>) {
    try {
      await updateTask({
        id: params.data.id,
        task: {
          name: params.newValue || "",
        },
      });
      toast({
        content: `Task name changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            name: params.oldValue || "",
          },
        ],
      });
      toast({
        content: "There was an error changing the Task name",
        variant: "error",
      });
    }
  }

  async function handleStatusCellChanged(
    params: NewValueParams<Task, TTaskStatus>,
  ) {
    if (!params.oldValue || !params.newValue) {
      // TODO: Look into if/when AgGrid will have these values be null or undefined as the types suggest
      window.console.warn(
        "AgGrid called onCellChanged with null or undefined values",
      );
      return;
    }

    try {
      await updateTask({
        id: params.data.id,
        task: {
          status: params.newValue,
        },
      });
      toast({
        content: `Task marked as "${
          TaskStatus[params.newValue].label
        }" successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            status: params.oldValue,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task status",
        variant: "error",
      });
    }
  }

  async function handleDateCellChanged(params: NewValueParams<Task, string>) {
    try {
      if (params.newValue?.toString() === params.oldValue?.toString()) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          due_date: params.newValue || null,
        },
      });
      toast({
        content: `Task due date changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            due_date: params.oldValue || null,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task due date",
        variant: "error",
      });
    }
  }

  async function handleAssigneeCellChanged(
    params: NewValueParams<Task, string>,
  ) {
    try {
      if (params.newValue === params.oldValue) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          assignee_id: params.newValue ? Number(params.newValue) : null,
        },
      });
      toast({
        content: `Task assignee changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            assignee_id: params.oldValue ? Number(params.oldValue) : null,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task assignee",
        variant: "error",
      });
    }
  }

  async function handleCollaboratorsCellChanged(
    params: NewValueParams<Task, string[]>,
  ) {
    try {
      if (!params.newValue && !params.oldValue) {
        return;
      }
      if (
        params.newValue?.every((val) => params.oldValue?.includes(val)) &&
        params.oldValue?.every((val) => params.newValue?.includes(val))
      ) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          collaborator_ids: params.newValue?.map((val) => Number(val)) || [],
        },
      });
      toast({
        content: `Task collaborators changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            collaborator_ids: params.oldValue?.map((val) => Number(val)) || [],
          },
        ],
      });
      toast({
        content: "There was an error changing the Task collaborators",
        variant: "error",
      });
    }
  }

  const columns: Columns = useMemo(
    () => [
      {
        headerName: "",
        width: 30,
        minWidth: 30,
        editable: true,
        cellRenderer: TableComponent.checkboxCell,
        cellRendererParams: () => ({
          rounded: true,
        }),
        cellEditorParams: () => ({
          rounded: true,
        }),
        cellEditor: TableComponent.checkboxCell,
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.status === TaskStatus.Done.value,
        valueSetter: (params: ValueSetterParams<Task>) => {
          params.data.status = params.newValue
            ? TaskStatus.Done.value
            : TaskStatus["In Progress"].value;
          return true;
        },
        sortable: false,
        onCellValueChanged: handleCheckboxCellChanged,
      },
      {
        headerName: "Name",
        field: "name",
        cellRenderer: TableComponent.identifierCellRenderer,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => ({
          onClick: () => taskDetail.open(props.data),
        }),
        width: 300,
        minWidth: 200,
        flex: 2,
        initialSort:
          sorting?.columnKey === "name" ? sorting.direction : undefined,
        comparator: (_, __, nodeA, nodeB) => {
          const a = nodeA.data.name.toLowerCase();
          const b = nodeB.data.name.toLowerCase();

          if (a === b) {
            return 0;
          }
          return a < b ? -1 : 1;
        },
        editable: true,
        cellEditor: TableComponent.identifierCellEditor,
        cellEditorParams: (props: CustomCellRendererProps<Task>) => ({
          onClick: () => taskDetail.open(props.data),
        }),
        onCellValueChanged: handleNameCellChanged,
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: {
          items: Object.values(TaskStatus).map((status) => ({
            label: status.label,
            value: status.value,
          })),
          renderSelected: ({ value }: Partial<SelectItem>) => {
            return TaskStatus[value as keyof typeof TaskStatus].Component();
          },
        },
        editable: true,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: {
          items: Object.values(TaskStatus).map((status) => ({
            label: status.label,
            value: status.value,
          })),
          renderSelected: ({ value }: Partial<SelectItem>) => {
            return TaskStatus[value as keyof typeof TaskStatus].Component();
          },
          defaultOpen: true,
        },
        minWidth: 120,
        initialSort:
          sorting?.columnKey === "status" ? sorting.direction : undefined,
        onCellValueChanged: handleStatusCellChanged,
      },
      {
        headerName: "Due Date",
        field: "due_date",
        valueGetter: (params: ValueGetterParams<Task>) =>
          params?.data?.due_date ? dayjs(params.data?.due_date).toDate() : null,
        cellRenderer: TableComponent.datePickerCell,
        cellRendererParams: {
          mode: "single",
        },
        cellEditor: TableComponent.datePickerCell,
        cellEditorParams: {
          mode: "single",
        },
        minWidth: 150,
        initialSort:
          sorting?.columnKey === "due_date" ? sorting.direction : undefined,
        editable: true,
        onCellValueChanged: handleDateCellChanged,
      },
      {
        headerName: "Assignee",
        field: "assignee_id",
        valueGetter: (params: ValueGetterParams<Task>) =>
          String(params.data?.assignee_id) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar size="md" colorIndex={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  <span>{user.full_name}</span>
                </div>
              ),
            })) || [];

          return {
            ...props,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component: Component,
            }: Partial<SelectItem>) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                    })}
                </div>
              );
            },
          };
        },
        minWidth: 200,
        flex: 2,
        initialSort:
          sorting?.columnKey === "assignee_id" ? sorting.direction : undefined,
        comparator: (_, __, nodeA, nodeB, isDesc) => {
          const a =
            users?.data
              .find((user) => user.id === nodeA.data.assignee_id)
              ?.full_name?.toLowerCase() || "";
          const b =
            users?.data
              .find((user) => user.id === nodeB.data.assignee_id)
              ?.full_name?.toLowerCase() || "";

          if (a === b) {
            return 0;
          }
          if (!a) {
            return isDesc ? 1 : -1;
          }
          if (!b) {
            return isDesc ? -1 : 1;
          }
          return a < b ? -1 : 1;
        },
        editable: true,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: (props: CustomCellEditorProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar size="md" colorIndex={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  <span>{user.full_name}</span>
                </div>
              ),
            })) || [];

          return {
            ...props,
            defaultOpen: true,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component: Component,
            }: Partial<SelectItem>) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                    })}
                </div>
              );
            },
          };
        },
        onCellValueChanged: handleAssigneeCellChanged,
      },
      {
        headerName: "Collaborators",
        field: "collaborator_ids",
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.collaborator_ids.map(String) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: ({ hideName }: { hideName: boolean }) => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar size="md" colorIndex={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  {!hideName && <span>{user.full_name}</span>}
                </div>
              ),
            })) || [];

          return {
            ...props,
            multiple: true,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component: Component,
            }: Partial<SelectItem>) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                      // @ts-expect-error - TS doesn't know about the hideName prop
                      hideName: true,
                    })}
                </div>
              );
            },
          };
        },
        minWidth: 150,
        sortable: false,
        editable: true,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.data.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: ({ hideName }: { hideName: boolean }) => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar size="md" colorIndex={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  {!hideName && <span>{user.full_name}</span>}
                </div>
              ),
            })) || [];

          return {
            ...props,
            multiple: true,
            items: userOptions,
            defaultOpen: true,
            renderSelected: ({
              label,
              value,
              Component,
            }: Omit<SelectItem, "Component"> & {
              Component: (params: {
                hideName: boolean;
                label: string;
                value: string;
              }) => JSX.Element;
            }) => {
              return (
                <div className="tw-mr-1" key={value}>
                  {Component &&
                    Component({
                      label: getInitials(label || ""),
                      value: value || "",
                      hideName: true,
                    })}
                </div>
              );
            },
          };
        },
        onCellValueChanged: handleCollaboratorsCellChanged,
      },
      {
        headerName: "Associated Records",
        field: "taskables",
        editable: false,
        cellRenderer: (params: CustomCellRendererProps<Task>) =>
          params.data && (
            <div className="tw-px-2.5">
              <AssociatedRecordCell task={params.data} />
            </div>
          ),
        minWidth: 300,
        sortable: false,
      },
    ],
    [tasksData, users, sorting],
  );

  function handleSortChange(column: string | null, direction?: "asc" | "desc") {
    setSorting(
      column
        ? {
            columnKey: column as keyof Task,
            direction: direction as "asc" | "desc",
          }
        : undefined,
    );
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: context.workspaceID!,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if (isPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  if (!tasksData) {
    return <TasksEmptyState />;
  }

  return tasksData.data.length ? (
    <Table
      width="100%"
      columns={columns}
      rows={tasksData.data}
      onSortChange={handleSortChange}
    />
  ) : (
    <TasksEmptyState />
  );
}

export default observer(TasksTable);
