import React from "react";
import { Bar, ComposedChart, LabelList, Line, ResponsiveContainer, XAxis, } from "recharts";
var labelWidth = 32;
var labelHeight = 24;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CustomAxisTick = function (props) {
    var _a;
    var x = props.x, y = props.y, payload = props.payload, isMultiXLines = props.isMultiXLines;
    var name = ((_a = payload === null || payload === void 0 ? void 0 : payload.value) === null || _a === void 0 ? void 0 : _a.toString()) || "";
    var words = isMultiXLines ? name.split("-") : [name];
    return (React.createElement("text", { x: x, y: y + 8, width: 124, textAnchor: "middle", fill: "#353549", fontFamily: "Poppins", fontSize: 10, fontWeight: 500 }, words.map(function (line, index) { return (React.createElement("tspan", { x: x, dy: index * 14, key: index }, line)); })));
};
export var StackedBarChart = function (_a) {
    var _b = _a.wireKey, wireKey = _b === void 0 ? "none" : _b, data = _a.data, keys = _a.keys, colors = _a.colors, isMultiXLines = _a.isMultiXLines;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var FloatingValueLabel = function (props) {
        var _a;
        var x = props.x, y = props.y, width = props.width, name = props.name;
        var barValue = (_a = data.find(function (d) { return d.name === name; })) === null || _a === void 0 ? void 0 : _a.total;
        if (!barValue) {
            return null;
        }
        return (React.createElement("g", null,
            React.createElement("rect", { x: x - 4, y: y - labelWidth, rx: 4, ry: 4, width: labelWidth, height: labelHeight, fill: "#F6F6F9" }),
            React.createElement("text", { x: x + width / 2, y: y - labelHeight / 2 - 4, fill: "#353549", textAnchor: "middle", fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }, barValue)));
    };
    return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
        React.createElement(ComposedChart, { data: data, margin: {
                top: 40,
            } },
            React.createElement(XAxis, { interval: 0, dataKey: "name", tickLine: false, tick: React.createElement(CustomAxisTick, { isMultiXLines: isMultiXLines }), axisLine: {
                    stroke: "#E4E4EB",
                    strokeWidth: 1,
                } }),
            wireKey !== "none" && (React.createElement(Line, { type: "linear", dataKey: wireKey, stroke: "#313181", strokeWidth: 2, dot: false })),
            keys
                .map(function (key, index) {
                var _a, _b;
                return index === 0 ? (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", barSize: 24, fill: (_a = colors.find(function (c) { return c.key === key; })) === null || _a === void 0 ? void 0 : _a.color },
                    React.createElement(LabelList, { dataKey: "total", content: FloatingValueLabel }))) : (React.createElement(Bar, { key: "bar-".concat(key), dataKey: key, stackId: "a", barSize: 24, fill: (_b = colors.find(function (c) { return c.key === key; })) === null || _b === void 0 ? void 0 : _b.color }));
            })
                .reverse())));
};
