import React from "react";

import { DashboardComponent } from "@/api";

export default function HeaderCalculationSection({
  widgetData,
  title,
}: {
  widgetData: DashboardComponent["data"];
  title: string;
}) {
  return (
    <div className="tw-flex tw-h-[80px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-bg-neutral-50 tw-px-4 tw-text-left tw-shadow-widget">
      <div className="tw-items-left tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center">
        <div className="tw-flex tw-flex-col tw-justify-center">
          <div className="tw-items-left tw-flex tw-w-full tw-flex-col">
            <div className="tw-flex tw-items-baseline tw-text-xl tw-font-semibold tw-leading-8 tw-text-neutral-500">
              {widgetData?.value}
            </div>
            <div className="tw-text-xs tw-font-medium tw-leading-4 tw-text-neutral-300">
              {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
