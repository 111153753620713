import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React, { ChangeEvent, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { PiUploadSimpleBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { ImportThemisRecordsPathParamsRecordType } from "@/api";
import { exportThemisRecords } from "@/api/gen/axios/exportsController/exportThemisRecords";
import { importThemisRecords } from "@/api/gen/axios/importsController/importThemisRecords";
import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

function ImportButton({
  recordType,
}: {
  recordType: ImportThemisRecordsPathParamsRecordType;
}) {
  const mainStore = useMainStore();
  const { companyID, workspaceID } = mainStore.context;

  const [showPopup, setShowPopup] = useState(false);
  const queryClient = useQueryClient();

  async function downloadTemplate() {
    if (!companyID) {
      return;
    }
    exportThemisRecords(companyID, recordType, { is_template: true });
  }

  async function processExcel(event: ChangeEvent<HTMLInputElement>) {
    setShowPopup(false);
    const { files } = event.target;
    if (!files || files.length === 0 || !companyID || !workspaceID) {
      return;
    }
    await importThemisRecords(
      companyID,
      recordType,
      { file: files[0], workspace_id: workspaceID },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    queryClient.invalidateQueries({
      queryKey: [recordType],
    });
  }

  const downloadTemplateButton = (
    <div>
      <button onClick={downloadTemplate}>
        <Icon name="download" color="generalWhite" />
        Download XLSX Template
      </button>
    </div>
  );

  const detailedMessage = (() => {
    switch (recordType) {
      case "projects":
        return "Fill in your Project details and then add your tasks details below the field headers.";
      default:
        return "Fill in your record details";
    }
  })();

  const directUploadProvider = (
    <DirectUploadProvider
      onSuccess={() => {}}
      render={() => (
        <div className="import-csv-file-select">
          <button>
            <Icon name="upload" color="generalWhite" />
            Import XLSX File
          </button>
          <input
            data-testid="file-upload-input"
            type="file"
            onChange={(event) => processExcel(event)}
            className="cursor-pointer"
          />
        </div>
      )}
    />
  );

  const renderButtonTrigger = (
    <Button
      size="sm"
      color="tertiary"
      LeftIcon={PiUploadSimpleBold}
      data-testid="import-button"
    >
      Import
    </Button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      nested
    >
      <div className="table-dropdown import-dropdown">
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">1</div>
            <h3>Download XLSX File</h3>
          </div>
          {downloadTemplateButton}
        </div>
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">2</div>
            <h3>Add / Edit info in XLSX template</h3>
          </div>
          <div className="import-csv-blurb">{detailedMessage}</div>
        </div>
        <div className="import-csv-section">
          <div className="import-csv-section-title">
            <div className="users-circle">3</div>
            <h3>Upload XLSX File</h3>
          </div>
          <div className="cursor-pointer">{directUploadProvider}</div>
        </div>
      </div>
    </Popup>
  );
}

export default observer(ImportButton);
