export function formatWorkspaces(
  workspace_ids?: [] | object | string,
  workspace_id?: number[],
) {
  if (!workspace_ids) {
    return [Number(workspace_id)];
  }
  if (Array.isArray(workspace_ids)) {
    return workspace_ids;
  }
  if (typeof workspace_ids === "object") {
    const arraySearch = Object.values(workspace_ids);
    return arraySearch;
  }
  if (typeof workspace_ids === "string") {
    return (workspace_ids as string)?.split(",").map(Number);
  }

  return [];
}
