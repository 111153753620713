import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
} from "@themis/ui";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDashboardComponents } from "@/api/queries/dashboardComponents";
import { useWorkspaces } from "@/api/queries/workspaces";
import { SHORT_API_FORMAT } from "@/components/constants";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { DATE_FORMAT } from "@/constants";
import { useSearchParams } from "@/hooks/useSearchParams";
import mainStore from "@/stores/Main";
import { Workspace } from "@/stores/types/workspace-types";

import { CustomTimeRangeModal } from "../components/CustomTimeRangeModal";
import { HomeHeader } from "../components/HomeHeader";
import { HomeTabs } from "../components/HomeTabs";
import ListWorkspaces from "../components/ListWorkspaces";
import { TimeFrameSelect } from "../components/TimeFrameSelect";
import ClosedRecords from "../components/widgets/ClosedRecords";
import CloseRecords from "../components/widgets/CloseRecords";
import ComplaintOverTime from "../components/widgets/ComplaintOverTime";
import ComplaintsBySource from "../components/widgets/ComplaintsBySource";
import HeaderCalculationSection from "../components/widgets/HeaderCalculationSection";
import IssuesByDepartment from "../components/widgets/IssuesByDepartment";
import IssuesByRisk from "../components/widgets/IssuesByRisk";
import IssuesBySource from "../components/widgets/IssuesBySource";
import LibraryRecords from "../components/widgets/LibraryRecords";
import PublishedMarketingByType from "../components/widgets/PublishedMarketingByType";
import PublishedMarketingRecords from "../components/widgets/PublishedMarketingRecords";
import RecordCompletionRate from "../components/widgets/RecordCompletionRate";
import { formatWorkspaces } from "../config/formatWorkspaces";
import { widgetSessionStorageKey } from "../config/localStorage";
import {
  calculateQuarterDate,
  isQuarter,
  Quarter,
  QUARTERS,
} from "../help/utils";

export type HomePageSearchParams = {
  days?: string;
  start_date?: string;
  end_date?: string;
  quarter_start_date?: string;
  quarter_end_date?: string;
  workspace_ids?: string[];
  sessionId?: string;
};

const DEFAULT_TIME_FRAME = "30" as const;

export const CUSTOM_TIME_FRAME = "custom" as const;

const TIME_FRAME_OPTIONS = [
  { value: "1", label: "1 Day" },
  { value: "7", label: "1 Week" },
  { value: "30", label: "1 Month" },
  { value: "90", label: "3 Months" },
  { value: "180", label: "6 Months" },
  { value: "365", label: "1 Year" },
  { value: CUSTOM_TIME_FRAME, label: "Custom Time Range" },
  { value: QUARTERS.Q1, label: "Q1" },
  { value: QUARTERS.Q2, label: "Q2" },
  { value: QUARTERS.Q3, label: "Q3" },
  { value: QUARTERS.Q4, label: "Q4" },
  { value: QUARTERS.FISCAL_YEAR, label: "This Fiscal Year" },
];

const isStringEmptyObject = (str: string) => str === "{}";

export function CompanyRetro() {
  const [isCustomTimeFrameModalOpen, setIsCustomTimeFrameModalOpen] =
    useState(false);

  const { workspace_id } = useParams<{ workspace_id: string }>();

  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const moduleWorkspacesNames = useMemo(() => {
    const list: { [key: string]: string } = {};
    moduleWorkspaces?.forEach((mw) => {
      list[mw.themis_module.identifier] = mw.name;
    });
    return list;
  }, [moduleWorkspaces]);

  const [searchParams, setSearchParams] =
    useSearchParams<HomePageSearchParams>();

  const currentUserID = String(mainStore.users.user.id);
  const selectedTimeFrame = searchParams.days || DEFAULT_TIME_FRAME;

  const {
    data: widgetData,
    isLoading,
    error,
  } = useDashboardComponents({
    workspaceId: Number(workspace_id),
    systemName: "retrospective",
    ...searchParams,
  });

  const { data: workspacesData } = useWorkspaces();

  useEffect(() => {
    if (
      (!searchParams.sessionId && searchParams.workspace_ids) ||
      (searchParams.sessionId && searchParams.sessionId !== currentUserID)
    ) {
      setSearchParams({ ...{}, sessionId: currentUserID }, true, true);
    }
  }, [searchParams]);

  useEffect(() => {
    const storageParams = sessionStorage.getItem(
      widgetSessionStorageKey(currentUserID),
    );
    const searchParamsStr = JSON.stringify(searchParams);

    if (!isStringEmptyObject(searchParamsStr) || !storageParams) {
      return;
    }

    // If local storage exists and there's no search params, we want to apply saved params to URL
    setSearchParams(JSON.parse(storageParams));
  }, [searchParams]);

  function getWidgetData(systemName: string) {
    return widgetData?.data[0].components.find(
      ({ system_name }) => system_name === systemName,
    )?.data;
  }

  function handleTimeFrameSelect(value: string) {
    if (value === CUSTOM_TIME_FRAME) {
      setIsCustomTimeFrameModalOpen(true);
    } else if (isQuarter(value)) {
      const { start_date, end_date } = calculateQuarterDate(value as Quarter);

      setSearchParams(
        {
          ...searchParams,
          days: value,
          quarter_start_date: dayjs(start_date).format(SHORT_API_FORMAT),
          quarter_end_date: dayjs(end_date).format(SHORT_API_FORMAT),
        },
        true,
        true,
      );
    } else {
      setSearchParams({ ...searchParams, days: value }, true, true);
    }

    const searchParamsStr = JSON.stringify(searchParams);
    // We want save search params to local storage every time it changes
    sessionStorage.setItem(
      widgetSessionStorageKey(currentUserID),
      searchParamsStr,
    );
  }

  function handleSetCustomTimeRange({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) {
    setIsCustomTimeFrameModalOpen(false);

    setSearchParams(
      {
        ...searchParams,
        days: CUSTOM_TIME_FRAME,
        start_date,
        end_date,
      },
      true,
      true,
    );
  }

  const workspaceIds = formatWorkspaces(searchParams.workspace_ids, [
    Number(workspace_id),
  ]);

  const workspacesDisplay = workspaceIds
    .map((workspaceId: number | string) => {
      return workspacesData?.workspaces.find(
        ({ id }) => id === Number(workspaceId),
      );
    })
    .filter(
      (workspace: Workspace | undefined): workspace is Workspace =>
        workspace !== undefined,
    );

  return (
    <PageLayout>
      <HomeHeader hasManageWorkspaces />
      <HomeTabs />
      {!(isLoading || error) && (
        <PageContent>
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-[14px]">
            <header className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-self-stretch">
              <section className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
                <div className="tw-flex tw-items-center tw-gap-2">
                  <p className="tw-truncate tw-text-xs tw-text-neutral-300">
                    Data used from these workspaces
                  </p>
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <ListWorkspaces
                      workspaces={workspacesDisplay || []}
                      displayCount={4}
                    />
                  </div>
                </div>
                <div className="tw-flex tw-items-center tw-gap-2">
                  {searchParams.days === CUSTOM_TIME_FRAME && (
                    <div className="tw-flex tw-items-center tw-gap-1">
                      <p className="tw-text-xs tw-font-medium tw-text-neutral-300">
                        {dayjs(searchParams.start_date).format(DATE_FORMAT)} -{" "}
                        {dayjs(searchParams.end_date).format(DATE_FORMAT)}
                      </p>
                      <IconButton
                        size="sm"
                        color="transparent"
                        Icon={PiPencilSimpleLineBold}
                        onClick={() => setIsCustomTimeFrameModalOpen(true)}
                      />
                    </div>
                  )}
                  <TimeFrameSelect
                    options={TIME_FRAME_OPTIONS}
                    selectedValue={selectedTimeFrame}
                    onSelect={handleTimeFrameSelect}
                    alignItemList="end"
                    classNameList="tw-max-h-[400px] tw-w-72"
                  />
                  {isCustomTimeFrameModalOpen && (
                    <CustomTimeRangeModal
                      onClose={() => setIsCustomTimeFrameModalOpen(false)}
                      onSetCustomTimeRange={handleSetCustomTimeRange}
                      isBackwardRange
                    />
                  )}
                </div>
              </section>
              <section className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
                <h2 className="tw-text-base tw-font-semibold tw-text-neutral-500">
                  Workspace Overview
                </h2>
              </section>
            </header>
            <div className="tw-flex tw-w-full tw-flex-col tw-gap-4">
              <div className="tw-flex tw-flex-wrap tw-gap-4">
                <CloseRecords
                  widgetData={getWidgetData(
                    "retro_overview_workspace_closed_records_ratio",
                  )}
                />
                <RecordCompletionRate
                  widgetData={getWidgetData(
                    "retro_overview_workspace_record_completion_rate",
                  )}
                />
                <ClosedRecords
                  widgetData={getWidgetData(
                    "retro_overview_workspace_closed_records_by_modules",
                  )}
                  moduleWorkspacesNames={moduleWorkspacesNames}
                />
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-4">
                <LibraryRecords
                  widgetData={getWidgetData("retro_overview_library_records")}
                  widgetCloseData={getWidgetData(
                    "retro_overview_library_closed_records_ratio",
                  )}
                  widgetCompleteData={getWidgetData(
                    "retro_overview_library_record_completion_rate",
                  )}
                  widgetAvgData={getWidgetData(
                    "retro_overview_library_avg_time_to_close",
                  )}
                />
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-4">
                <Accordion
                  type="single"
                  collapsible
                  defaultValue="item-1"
                  className="tw-mb-4 tw-w-full"
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger iconPosition="left">
                      <span className="tw-text-base tw-font-semibold">
                        Issue Management
                      </span>
                    </AccordionTrigger>
                    <AccordionContent className="tw-flex">
                      <div className="tw-min-w-screen tw-flex tw-w-screen tw-flex-wrap tw-gap-4 tw-p-1">
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_issues_closed_records_ratio",
                          )}
                          title={"Close Records"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_issues_record_completion_rate",
                          )}
                          title={"Completion"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_issues_avg_time_to_close",
                          )}
                          title={"Avg. time to close"}
                        />
                        <IssuesByRisk
                          widgetData={getWidgetData(
                            "retro_issues_closed_by_risks",
                          )}
                        />
                        <IssuesByDepartment
                          widgetData={getWidgetData(
                            "retro_issues_closed_by_department",
                          )}
                        />
                        <IssuesBySource
                          widgetData={getWidgetData(
                            "retro_issues_closed_by_source",
                          )}
                        />
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-4">
                <Accordion
                  type="single"
                  collapsible
                  defaultValue="item-1"
                  className="tw-mb-4 tw-w-full"
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger iconPosition="left">
                      <span className="tw-text-base tw-font-semibold">
                        Complaints
                      </span>
                    </AccordionTrigger>
                    <AccordionContent className="tw-flex">
                      <div className="tw-min-w-screen tw-flex tw-w-screen tw-flex-wrap tw-gap-4 tw-p-1">
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_complaints_closed_records_ratio",
                          )}
                          title={"Close Records"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_complaints_record_completion_rate",
                          )}
                          title={"Completion"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_complaints_avg_time_to_close",
                          )}
                          title={"Avg. time to close"}
                        />
                        <ComplaintOverTime
                          widgetData={getWidgetData(
                            "retro_complaints_over_time",
                          )}
                        />
                        <ComplaintsBySource
                          widgetData={getWidgetData(
                            "retro_complaints_by_source",
                          )}
                        />
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="tw-flex tw-flex-wrap tw-gap-4">
                <Accordion
                  type="single"
                  collapsible
                  defaultValue="item-1"
                  className="tw-mb-4 tw-w-full"
                >
                  <AccordionItem value="item-1">
                    <AccordionTrigger iconPosition="left">
                      <span className="tw-text-base tw-font-semibold">
                        Marketing
                      </span>
                    </AccordionTrigger>
                    <AccordionContent className="tw-flex">
                      <div className="tw-min-w-screen tw-flex tw-w-screen tw-flex-wrap tw-gap-4 tw-p-1">
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_marketing_closed_records_ratio",
                          )}
                          title={"Close Records"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_marketing_record_completion_rate",
                          )}
                          title={"Completion"}
                        />
                        <HeaderCalculationSection
                          widgetData={getWidgetData(
                            "retro_marketing_avg_time_to_close",
                          )}
                          title={"Avg. time to close"}
                        />
                        <PublishedMarketingRecords
                          widgetData={getWidgetData(
                            "retro_marketing_published_records",
                          )}
                        />
                        <PublishedMarketingByType
                          widgetData={getWidgetData(
                            "retro_marketing_published_material_types",
                          )}
                        />
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </PageContent>
      )}
    </PageLayout>
  );
}
