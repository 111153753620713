import { PieChart } from "@themis/ui";
import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

const PIE_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#FDB932",
  "#474DEB",
  "#FFBEC9",
  "#AA75F0",
];

export default function PublishedMarketingByType({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const mutated = useMemo(() => {
    const keys = Object.keys(widgetData || {});
    if (keys.length === 0) {
      return [];
    }
    return keys
      .sort((a, b) => a.localeCompare(b))
      .map((key, index) => ({
        name: formatKey(key),
        value: widgetData?.[key],
        color: PIE_COLORS[index],
      }));
  }, [widgetData]);

  function formatKey(key: string) {
    return key
      .replace(/_/gi, " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }
  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return valueSum === 0 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Material Types
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Material Types
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-relative tw-left-[-40px] tw-top-[80px] tw-z-[100] tw-inline-block tw-text-center tw-text-2xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-flex tw-flex-col">
              <PieChart data={mutated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
