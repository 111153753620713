import { PieChart } from "@themis/ui";
import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";

const PIE_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#FDB932",
  "#474DEB",
  "#FFBEC9",
  "#AA75F0",
];
export default function LibraryRecordsWithDueDatesApproaching({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const mutated = useMemo(() => {
    const keys = Object.keys(widgetData || {});
    if (keys.length === 0) {
      return [];
    }
    return keys.map((key, index) => ({
      name:
        key === "policy"
          ? "Policies"
          : key
              .replace(/_/gi, " ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
      value: widgetData?.[key],
      color: PIE_COLORS[index],
    }));
  }, [widgetData]);

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Library Records with Due Dates Approaching
        </div>
      </div>
      <div>
        <div className="tw-flex tw-h-[228px] tw-w-full tw-justify-evenly">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              Record Due Dates
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <PieChart data={mutated} />
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              % Library Record
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
