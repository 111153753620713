import { cn, PieChart } from "@themis/ui";
import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";
import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

const PIE_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#FDB932",
  "#474DEB",
  "#FFBEC9",
  "#AA75F0",
];

export default function ClosedRecords({
  widgetData,
  moduleWorkspacesNames,
}: {
  widgetData: DashboardComponent["data"];
  moduleWorkspacesNames?: { [key: string]: string };
}) {
  const mutated = useMemo(() => {
    const keys = Object.keys(widgetData || {});
    if (keys.length === 0) {
      return [];
    }
    return keys
      .sort((a, b) => a.localeCompare(b))
      .map((key, index) => ({
        name: moduleWorkspacesNames
          ? moduleWorkspacesNames[String(key)] ||
            nameForThemisModuleIdentifier(key as ModuleIdentifier)
          : nameForThemisModuleIdentifier(key as ModuleIdentifier),
        value: widgetData?.[key],
        color: PIE_COLORS[index],
      }));
  }, [widgetData]);

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);
  const isEmptyState = valueSum === 0;

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Closed Records
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div
              className={cn(
                "tw-relative tw-left-[-68px] tw-top-[100px] tw-z-[100] tw-inline-block tw-text-center tw-text-2xl tw-font-semibold",
                {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                },
              )}
            >
              {valueSum}
            </div>
            <div className="tw-flex tw-flex-col">
              <PieChart data={mutated} isEmptyState={isEmptyState} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
