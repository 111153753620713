import React from "react";
import { Bar, ComposedChart, LabelList, Line, ResponsiveContainer, XAxis, } from "recharts";
var labelWidth = 32;
var labelHeight = 24;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var RoundedBar = function (props) {
    var x = props.x, width = props.width, y = props.y, height = props.height, payload = props.payload;
    var barXStart = x + width / 2 - labelWidth / 2;
    return (React.createElement("g", null,
        React.createElement("rect", { x: barXStart, y: y, rx: 4, ry: 4, width: 24, height: height, fill: payload.color }),
        React.createElement("rect", { x: barXStart, y: y + height / 2, width: 24, height: height / 2, fill: payload.color })));
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var CustomAxisTick = function (props) {
    var _a;
    var x = props.x, y = props.y, payload = props.payload, isMultiXLines = props.isMultiXLines;
    var name = ((_a = payload === null || payload === void 0 ? void 0 : payload.value) === null || _a === void 0 ? void 0 : _a.toString()) || "";
    var words = isMultiXLines ? name.split("-") : [name];
    return (React.createElement("text", { x: x, y: y + 8, width: 124, textAnchor: "middle", fill: "#353549", fontFamily: "Poppins", fontSize: 10, fontWeight: 500 }, words.map(function (line, index) { return (React.createElement("tspan", { x: x, dy: index * 14, key: index }, line)); })));
};
export var BarChart = function (_a) {
    var _b = _a.wireKey, wireKey = _b === void 0 ? "none" : _b, data = _a.data, isMultiXLines = _a.isMultiXLines;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var FloatingValueLabel = function (props) {
        var _a;
        var x = props.x, y = props.y, width = props.width, value = props.value;
        var labelXStart = x + width / 2 - labelWidth / 2 - 4;
        var barValue = (_a = data.find(function (d) { return d.name === value; })) === null || _a === void 0 ? void 0 : _a.value;
        return (React.createElement("g", null,
            React.createElement("rect", { x: labelXStart, y: y - labelWidth, rx: 4, ry: 4, width: labelWidth, height: labelHeight, fill: "#F6F6F9" }),
            React.createElement("text", { x: x + width / 2 - 4, y: y - 15, fill: "#353549", textAnchor: "middle", fontFamily: "Poppins", fontWeight: 600, fontSize: 12 }, barValue)));
    };
    return (React.createElement(ResponsiveContainer, { width: "100%", height: "100%" },
        React.createElement(ComposedChart, { data: data, margin: {
                top: 40,
            } },
            React.createElement(XAxis, { interval: 0, dataKey: "name", tickLine: false, tick: React.createElement(CustomAxisTick, { isMultiXLines: isMultiXLines }), axisLine: {
                    stroke: "#E4E4EB",
                    strokeWidth: 1,
                } }),
            wireKey !== "none" && (React.createElement(Line, { type: "linear", dataKey: wireKey, stroke: "#313181", strokeWidth: 2, dot: false })),
            React.createElement(Bar, { dataKey: "value", minPointSize: 0, shape: RoundedBar },
                React.createElement(LabelList, { dataKey: "name", content: FloatingValueLabel })))));
};
