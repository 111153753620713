import {
  IconButton,
  MiniTag,
  Select,
  TextInput,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React, { useMemo, useState } from "react";
import { PiCheckBold, PiLinkSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { RecordShow } from "@/api/gen";
import { useListControlCategories } from "@/api/queries/controlCategories";
import { useListShowRecords } from "@/api/queries/records";
import mainStore from "@/stores/Main";

import { getRatingColorName } from "../../config";

function LinkControlMappingMenu({
  linkedRecords,
  onLinkRecord,
}: {
  recordVersionId: number;
  linkedRecords: number[];
  onLinkRecord: (recordIds: number[], close: boolean) => void;
  onClose: () => void;
}) {
  const [filter, setFilter] = useState("");
  const [filterCategory, setFilterCategory] = useState("-1");
  const [newlyLinkedRecords, setNewlyLinkedRecords] = useState<number[]>([]);

  const isFiltered = (value: string) =>
    value.toLowerCase().includes(filter.toLowerCase());

  const isFilteredCategory = (value: number[] | undefined) => {
    if (filterCategory === "-1") {
      return true;
    }
    return value?.includes(Number(filterCategory));
  };

  const { workspace_id: workspaceID } = useParams<{
    workspace_id: string;
  }>();

  const controlMappings = useListShowRecords({
    data_type: "record:control_mapping",
    workspace_id: Number(workspaceID),
  });

  const companyID = mainStore.context.companyID!;
  const controlCategories = useListControlCategories(companyID);

  const controlCategoriesItems = useMemo(() => {
    const allCategories =
      controlCategories?.data?.data.map(({ id, title }) => ({
        label: title,
        value: id.toString(),
      })) || [];
    return [{ label: "All Categories", value: "-1" }, ...allCategories];
  }, [controlCategories]);

  const records = controlMappings?.data?.data;

  if (records && records.length > 0 && controlCategoriesItems) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-2 tw-px-5">
        <TextInput
          size="lg"
          placeholder="Search Controls"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFilter(e.target.value)
          }
        />
        <Select
          searchable
          items={controlCategoriesItems}
          selected={filterCategory}
          onSelect={(value) => {
            setFilterCategory(value);
          }}
        />
        <div className="tw-py-4">
          {records && records.length > 0 && (
            <div className="tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-generals-underline" />
          )}
          {records &&
            records
              .filter(
                (r: RecordShow) =>
                  isFiltered(r.meta?.name || r.global_id) &&
                  isFilteredCategory(
                    r.latest_record_version?.data?.control_category?.ids,
                  ),
              )
              .sort((a: RecordShow, b: RecordShow) => {
                if (a.meta?.name && b.meta?.name) {
                  return a.meta.name.localeCompare(b.meta.name);
                }
                return 0;
              })
              .map((record: RecordShow) => {
                const controlEffectivenessRating =
                  record.latest_record_version?.data
                    ?.control_effectiveness_rating;
                const alreadyLinked =
                  linkedRecords.includes(Number(record.id)) ||
                  newlyLinkedRecords.includes(Number(record.id));
                const description =
                  record?.latest_record_version?.data?.description?.value || "";
                return (
                  <div
                    key={`record-${record.id}`}
                    className="tw-flex tw-items-center tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-py-2"
                  >
                    <div className="tw-flex tw-flex-col">
                      <div className="tw-text-sm tw-font-medium tw-text-neutral-500">
                        {record.name ? record.name : record.global_id}
                      </div>
                      {description && (
                        <div className="tw-line-clamp-2 tw-text-xs tw-font-medium tw-text-neutral-300">
                          {description}
                        </div>
                      )}
                    </div>
                    <div className="tw-flex-grow" />
                    <MiniTag
                      color={getRatingColorName(
                        controlEffectivenessRating?.color,
                      )}
                      size="md"
                    >
                      {controlEffectivenessRating?.value
                        ? controlEffectivenessRating.title
                        : "Not rated"}
                    </MiniTag>
                    <Tooltip>
                      <TooltipTrigger asChild={!alreadyLinked}>
                        {alreadyLinked ? (
                          <div className="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-md hover:tw-bg-primary-25">
                            <PiCheckBold className="tw-h-4 tw-w-4 tw-text-neutral-200" />
                          </div>
                        ) : (
                          <IconButton
                            color="transparent"
                            size="sm"
                            Icon={PiLinkSimpleBold}
                            onClick={() => {
                              if (record.id) {
                                onLinkRecord(
                                  newlyLinkedRecords.concat(record.id),
                                  false,
                                );
                                setNewlyLinkedRecords([
                                  ...newlyLinkedRecords,
                                  record.id,
                                ]);
                              }
                            }}
                          />
                        )}
                      </TooltipTrigger>
                      <TooltipContent align="center" side="bottom">
                        {alreadyLinked ? "Already Linked" : "Link control"}
                      </TooltipContent>
                    </Tooltip>
                  </div>
                );
              })}
        </div>
      </div>
    );
  }
  return (
    <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center tw-text-sm tw-font-semibold tw-text-neutral-500">
      There are no controls to link within this module
    </div>
  );
}

export default LinkControlMappingMenu;
