import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { ExportThemisRecordsPathParamsRecordType } from "@/api";
import { exportThemisRecords } from "@/api/gen/axios/exportsController/exportThemisRecords";
import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

function ExportButton({
  recordType,
}: {
  recordType: ExportThemisRecordsPathParamsRecordType;
}) {
  const mainStore = useMainStore();
  const { companyID, workspaceID } = mainStore.context;

  const [showPopup, setShowPopup] = useState(false);

  const renderButtonTrigger = (
    <Button
      size="sm"
      color="tertiary"
      LeftIcon={PiDownloadSimpleBold}
      data-testid="import-button"
    >
      Export
    </Button>
  );

  function processTableDownload() {
    if (!companyID || !workspaceID) {
      return;
    }
    exportThemisRecords(companyID, recordType, {
      is_template: false,
    });
  }

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      nested
    >
      <div className="table-dropdown export-table-dropdown">
        <p>Click the button below to export the table to an Excel file.</p>
        <button className="export-table-button" onClick={processTableDownload}>
          <Icon name="download" color="generalWhite" />
          Export to XLSX File
        </button>
      </div>
    </Popup>
  );
}

export default observer(ExportButton);
