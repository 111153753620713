import { LinkIconButton } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import { LinkProps } from "react-router-dom";

export function Header({
  title,
  HeaderOptions,
  backButtonLinkTo,
  hideBottomBorder,
}: {
  backButtonLinkTo?: LinkProps["to"];
  hideBottomBorder?: boolean;
  title: React.ReactNode;
  HeaderOptions?: React.ReactNode;
}) {
  return (
    <header
      className={classNames(
        "tw-flex tw-min-h-12 tw-items-center tw-justify-between tw-self-stretch tw-bg-neutral-25 tw-px-6",
        {
          "tw-border-0 tw-border-b tw-border-solid tw-border-neutral-100":
            !hideBottomBorder,
        },
      )}
    >
      <div className="tw-flex tw-items-center tw-gap-3">
        {backButtonLinkTo && (
          <LinkIconButton
            to={backButtonLinkTo}
            Icon={PiCaretLeftBold}
            color="tertiary"
          />
        )}
        <h1 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          {title}
        </h1>
      </div>
      {HeaderOptions && (
        <div className="tw-flex tw-items-center tw-justify-end tw-gap-1">
          {HeaderOptions}
        </div>
      )}
    </header>
  );
}
