import { StackedBarChart } from "@themis/ui";
import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

export default function IssuesByRisk({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const essentialKeys: string[] = ["high", "medium", "low"];
  const keys: string[] = [];
  const mutated = useMemo(() => {
    if (!widgetData) {
      return {
        keys: [],
        data: [],
        colors: [],
      };
    }
    const mutatedData: {
      name: string;
      total: number;
      [key: string]: string | number;
    }[] = [];
    const ranges = Object.keys(widgetData);
    ranges.forEach((range) => {
      let total = 0;
      const sources = Object.keys(widgetData[range]);
      sources.forEach((source) => {
        if (
          essentialKeys.indexOf(source) === -1 &&
          keys.indexOf(source) === -1
        ) {
          keys.push(source);
        }
        total += widgetData[range][source];
      });
      mutatedData.push({
        name: range,
        total,
        ...widgetData[range],
      });
    });

    return {
      keys: essentialKeys.concat(keys),
      data: mutatedData,
      colors: [
        { key: "low", color: "#66AA22" },
        { key: "medium", color: "#FF9900" },
        { key: "high", color: "#EB2E4E" },
      ],
    };
  }, [widgetData]);

  function formatKey(key: string) {
    return key
      .replace(/_/gi, " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  }

  return mutated.data.length < 1 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Risk
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Risk
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-green-3" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Low
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-yellow-4" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Medium
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-warning-300" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            High
          </div>
        </div>
        {keys.map((key) => {
          return (
            <div className="tw-flex tw-items-center tw-gap-[6px]" key={key}>
              <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-primaryDim-600" />
              <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
                {formatKey(key)}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <StackedBarChart
            wireKey="total"
            data={mutated.data}
            keys={mutated.keys}
            colors={mutated.colors}
            isMultiXLines
          />
        </div>
      </div>
    </div>
  );
}
