import { useQuery } from "@tanstack/react-query";

import { formatWorkspaces } from "@/features/home/config/formatWorkspaces";
import { isQuarter } from "@/features/home/help/utils";
import {
  CUSTOM_TIME_FRAME,
  HomePageSearchParams,
} from "@/features/home/pages/CompanyRetro";

import { GetDashboardsQueryParamsSystemName } from "../gen";
import { getDashboards } from "../gen/axios/dashboardsController";

export const DASHBOARD_COMPONENTS_QUERY_KEY = "dashboard_components";

export function useDashboardComponents({
  workspaceId,
  systemName,
  ...params
}: {
  workspaceId: number;
  systemName: GetDashboardsQueryParamsSystemName;
} & HomePageSearchParams) {
  const {
    days,
    start_date,
    end_date,
    quarter_start_date,
    quarter_end_date,
    workspace_ids,
  } = params;

  return useQuery({
    queryKey: [
      DASHBOARD_COMPONENTS_QUERY_KEY,
      workspaceId,
      systemName,
      { params },
    ],
    queryFn: () => {
      function getTimeRangeParams() {
        if (days === CUSTOM_TIME_FRAME) {
          return { start_date, end_date };
        }

        if (isQuarter(days || "")) {
          return { start_date: quarter_start_date, end_date: quarter_end_date };
        }

        if (!days) {
          return { days: 30 };
        }

        return { days: Number(days) };
      }

      return getDashboards(workspaceId, {
        ...getTimeRangeParams(),

        filtered_workspace_ids: formatWorkspaces(workspace_ids, [workspaceId]),
        system_name: systemName,
      });
    },
    enabled: !!workspaceId && !!systemName,
  });
}
