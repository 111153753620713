import {
  IconButton,
  LinkButton,
  ToggleButtonGroup,
  ToggleButtonGroupItem,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import React from "react";
import {
  PiDotsNineBold,
  PiInfoBold,
  PiListBulletsBold,
  PiPlusBold,
} from "react-icons/pi";
import { generatePath, useRouteMatch } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import AccountImport from "@/features/accounts/components/AccountImport";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import { useSearchParams } from "@/hooks/useSearchParams";

import { AccountList } from "../components/AccountsList";
import { ACCOUNT_ROUTES } from "../pages";
import AccountsHeader from "./AccountsHeader";

export type AccountSearchParams = {
  view?: AccountListView;
};

export type AccountListView = "grid" | "table";

function AccountsContainer({ children }: { children: React.ReactNode }) {
  const [{ view }] = useSearchParams<AccountSearchParams>();

  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-96 tw-flex-col tw-gap-4 tw-overflow-auto",
        {
          "tw-px-16": view === "grid",
        },
      )}
    >
      {children}
    </div>
  );
}

function ActionsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline tw-bg-white tw-px-6 tw-py-2">
      {children}
    </div>
  );
}

function TitleContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-items-center tw-gap-1">{children}</div>;
}

export function Accounts() {
  const { url } = useRouteMatch();
  const [{ view }, setSearchParams] = useSearchParams<AccountSearchParams>();

  const accountsListView = view || "table";
  const mainStore = useMainStore();

  function handleViewChange(newView: AccountListView) {
    setSearchParams({ view: newView }, true);
  }

  return (
    <PageLayout>
      <Header
        title={
          <TitleContainer>
            Accounts
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton Icon={PiInfoBold} color="transparent" size="sm" />
              </TooltipTrigger>
              <TooltipContent className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4">
                <h3 className="tw-text-base tw-font-semibold">Accounts</h3>
                <p className="tw-font-medium">
                  Accounts are business entities, Vendor or Partners used in
                  modules like Vendor Due Diligence and Questionnaires.
                </p>
              </TooltipContent>
            </Tooltip>
          </TitleContainer>
        }
        HeaderOptions={
          <>
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "accounts",
                )?.id
              }
            />
            <AccountImport />
          </>
        }
      />
      <AccountsHeader />
      <ActionsContainer>
        <ToggleButtonGroup
          size="lg"
          type="single"
          variant="transparent"
          value={accountsListView}
          onValueChange={handleViewChange}
        >
          <ToggleButtonGroupItem
            aria-label="Table View"
            value="table"
            className="tw-px-3"
          >
            <PiListBulletsBold className="tw-h-4 tw-w-4" />
            List
          </ToggleButtonGroupItem>
          <ToggleButtonGroupItem
            aria-label="Grid View"
            value="grid"
            className="tw-px-3"
          >
            <PiDotsNineBold className="tw-h-4 tw-w-4" />
            Grid
          </ToggleButtonGroupItem>
        </ToggleButtonGroup>
        <LinkButton
          LeftIcon={PiPlusBold}
          to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
            accountId: "new",
          })}
        >
          Add Account
        </LinkButton>
      </ActionsContainer>
      <PageContent>
        <AccountsContainer>
          <AccountList />
        </AccountsContainer>
      </PageContent>
    </PageLayout>
  );
}
