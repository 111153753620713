import { BarChart } from "@themis/ui";
import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

export default function PublishedMarketingRecords({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const mutated = useMemo(() => {
    if (!widgetData) {
      return {
        data: [],
      };
    }
    const mutatedData: {
      name: string;
      value: string;
      color: string;
      [key: string]: string | number;
    }[] = [];
    const ranges = Object.keys(widgetData);
    ranges.forEach((range) => {
      mutatedData.push({
        name: range,
        value: widgetData[range],
        color: "#8F92F9",
        ...widgetData[range],
      });
    });

    return {
      data: mutatedData,
    };
  }, [widgetData]);

  const valueSum = mutated.data.reduce(
    (acc, curr) => acc + Number(curr.value),
    0,
  );

  return valueSum < 1 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Marketing Records
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Marketing Records
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <BarChart data={mutated.data} isMultiXLines />
        </div>
      </div>
    </div>
  );
}
