import { useQuery } from "@tanstack/react-query";

import { ListThemisRecordFieldsPathParamsRecordType } from "../gen";
import { listThemisRecordFields } from "../gen/axios/fieldsController";

export const COLUMNS_QUERY_KEY = "columns";

export function useColumns({
  companyId,
  recordClass,
}: {
  companyId: number;
  recordClass: ListThemisRecordFieldsPathParamsRecordType;
}) {
  return useQuery({
    queryKey: [COLUMNS_QUERY_KEY, recordClass, companyId],
    queryFn: () => listThemisRecordFields(companyId, recordClass),
    enabled: !!companyId && !!recordClass,
  });
}
