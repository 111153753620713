import { LinkButton, Table } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { ListAccountsQueryParams } from "@/api";
import { useAccounts } from "@/api/queries/accounts";
import Loading from "@/components/Loading";
import { useSearchParams } from "@/hooks/useSearchParams";

import { ErrorContainer } from "../../../components/ErrorContainer";
import { ACCOUNT_TABLE_COLUMNS } from "../config/accountsTable";
import { ACCOUNT_ROUTES } from "../pages";
import { AccountListView, AccountSearchParams } from "../pages/Accounts";
import { AccountCard } from "./AccountCard";

function CardsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      data-testid="cards-container"
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-self-stretch tw-py-2"
    >
      {children}
    </div>
  );
}

function CardPlaceholderContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="tw-flex tw-max-h-[180px] tw-min-h-[180px] tw-flex-wrap tw-items-center tw-justify-center tw-gap-8 tw-overflow-hidden">
      {children}
    </section>
  );
}

function CardPlaceholder() {
  return (
    <div className="tw-h-[180px] tw-w-[180px] tw-rounded-xl tw-bg-neutral-50" />
  );
}

function EmptyStateContainer({
  accountsListView,
  children,
}: {
  accountsListView: AccountListView;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-inline-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
        {
          "tw-pt-4": accountsListView === "grid",
          "tw-pt-48": accountsListView === "table",
        },
      )}
    >
      {children}
    </div>
  );
}

export function AccountList() {
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [{ view }] = useSearchParams<AccountSearchParams>();
  const queryParams: ListAccountsQueryParams = {
    expand: "main_contact",
  };
  const { isPending, data, isError } = useAccounts(
    Number(workspace_id),
    queryParams,
  );

  const accounts = data?.data;

  const accountsListView = view || "table";

  if (isPending) {
    return <Loading loadingLayout={accountsListView} />;
  }

  if (isError || !accounts) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        Could not load accounts.
      </ErrorContainer>
    );
  }

  return (
    <>
      {accountsListView === "grid" ? (
        <CardsContainer>
          {accounts.map(({ id, logo, account_types, name, status }) => (
            <AccountCard
              linkTo={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: id,
              })}
              key={id}
              id={id}
              isActive={status === "Active"}
              logoURL={logo?.file_url}
              name={name}
              type={account_types[0]}
            />
          ))}
        </CardsContainer>
      ) : (
        <Table
          columns={ACCOUNT_TABLE_COLUMNS(Number(workspace_id))}
          rows={accounts}
          width="100%"
        />
      )}
      {!accounts.length && (
        <>
          {accountsListView === "grid" && (
            <CardPlaceholderContainer>
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
            </CardPlaceholderContainer>
          )}
          <EmptyStateContainer accountsListView={accountsListView}>
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              Add your first Account
            </h3>
            <p className="tw-w-[370px] tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
              {`To get started, add your first Account by clicking 'Add Account' and filling in the details.`}
            </p>
            <LinkButton
              LeftIcon={PiPlusBold}
              to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: "new",
              })}
            >
              Add Account
            </LinkButton>
          </EmptyStateContainer>
        </>
      )}
    </>
  );
}
